import { initializeApp } from "firebase/app";
import { Functions, connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { Firestore, getFirestore, collection, query, where, doc, getDoc, getDocs } from "firebase/firestore";
import IUser from "models/IUser";
import IExport from "../functions/src/models/IExport";
import ICsvModel from "../functions/src/models/ICsvModel";
import IUserDb from "../functions/src/models/IUserDb";
import moment from "moment";
import * as papaparse from 'papaparse';

class FirebaseBackend {


  //firestore: firebase.firestore.Firestore = {} as firebase.firestore.Firestore;
  functions: Functions = {} as Functions;
  firestore: Firestore = {} as Firestore;
  //storage: firebase.storage.Storage = {} as firebase.storage.Storage;
  //auth: firebase.auth.Auth = {} as firebase.auth.Auth;

  constructor(useEmulator = false) {
      try{
          const firebaseConfig: any = {		
            apiKey: process.env.REACT_APP_APIKEY,
            authDomain: process.env.REACT_APP_AUTHDOMAIN,
            databaseURL: process.env.REACT_APP_DATABASEURL,
            projectId: process.env.REACT_APP_PROJECTID,
            storageBucket: process.env.REACT_APP_STORAGEBUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
            appId: process.env.REACT_APP_APPID,
            measurementId: process.env.REACT_APP_MEASUREMENTID,
          }

          // Initialize Firebase
          const app = initializeApp(firebaseConfig);

          //this.auth = firebase.auth();
          this.functions = getFunctions(app, 'europe-west1');
          this.firestore = getFirestore(app);
          //this.storage = firebase.storage();
          
          // allow us to set "undefined" for fields in database
          //this.firestore.settings({ignoreUndefinedProperties: true});


          if(useEmulator)
          {
            console.warn("!!! USING EMULATOR LOCALHOST URL !!!")
            connectFunctionsEmulator(this.functions, "localhost", 5001);
          }

      }
      catch(err)
      {
        console.error("Init firebase error", err)
      }
  }

  getCsvExport = async () => {
    
    /*const docRef = doc(this.firestore, "", "");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const exportData = docSnap.data() as IExport;
      
      const csvContent = generateExportCsvFromObject(exportData.users)
      console.log("Document data:", csvContent);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }

    const querySnapshot = await getDocs(collection(this.firestore, "users"));
    const users = querySnapshot.docs.map(m => m.data() as IUserDb);
    //console.log("users => ", users);
    const csvContent = generateExportCsvFromObject(users)
      console.log("Document data:", csvContent);*/
  }


  signup = (user: IUser) => {
      const signupFunc = httpsCallable(this.functions, "signup");
    return signupFunc({user: user});
  }
  populate = () => {
      const populateFunc = httpsCallable(this.functions, "populateRemiseCodeCollection");
    return populateFunc();
  }
  checkResult = (email: string, rep1: string, rep2: string, rep3: string) => {
      const checkGameResultFunc = httpsCallable(this.functions, "checkGameResult");
    return checkGameResultFunc({
      email,
      rep1,
      rep2,
      rep3
    });
  }

}


let _fireBaseBackend: FirebaseBackend;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (useEmulator = false) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseBackend(useEmulator);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebase = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebase };




export function generateExportCsvFromObject(users: IUserDb[]){
  return papaparse.unparse(users.map(u => {
     return {
        ID_OPERATION: 'solonesp2405_priam_jeu',
        ID_CLIENT:'nespresso',
        SOURCE: 'JCNESP',
        DATE: moment(u.dateCheckGameResult?.toDate()).format("YYYY-MM-DD HH:mm:ss"),
        ECHEANCE:"",
        SOCIETE:"",
        ADRESSE:"",
        ADDRESSE2:"",
        CP:"",
        VILLE:"",
        PAYS:"",
        SECTEUR:"",
        EFFECTIF:"",
        SIREN:"",
        SIRET:"",
        TITRE:"",
        NOM: u.lastName,
        PRENOM: u.firstName,
        EMAIL: u.email,
        TEL: "",
        MOBILE: u.telephone,
        FAX:"",
        FONCTION:"",
        SERVICE:"",
        NAF:"",
        QUALIF1: u.newsletterInscription ? "1":"0",
        QUALIF2:u.external_user_id,
        QUALIF3:"",
        QUALIF4:"",
        QUALIF5:"",
        QUALIF6:"",
        QUALIF7:"",
        QUALIF8:"",
        QUALIF9:u.remiseCode,
        QUALIF10:u.priceWin,
        QUALIF11:"",
        QUALIF12:"",
        QUALIF13:"",
        QUALIF14:"",
        QUALIF15:"",
        QUALIF16:"",
        QUALIF17:"",
        QUALIF18:"",
        QUALIF19:"",
        QUALIF20:"",
        QUALIF21:"",
        QUALIF22:"",
        QUALIF23:"",
        QUALIF24:"",
        QUALIF25:"",
        QUALIF26:"",
        QUALIF27:"",
        QUALIF28:"",
        QUALIF29:"",
        QUALIF30:"",
     } as ICsvModel;
  }), {
     delimiter: ";"
  });
}
