import React from 'react';
import "./MentionsLegales.scss";
const MentionsLegales: React.FC = () => {
    return (
<>
<div id="mentions-legales" className="c8 doc-content">
    <p className="c0 c3"><span className="c5"></span></p>
    <p className="c0"><span className="c6">&nbsp;</span><span className="c7">JEU VOTRE CAFE NESPRESSO PREFERE </span>
    </p>
    <p className="c0 c3"><span className="c4"></span></p>
    <p className="c0"><span className="c7">Remise de 5&euro; </span></p>
    <p className="c0 c3"><span className="c4"></span></p>
    <p className="c1"><span className="c2">{`« Offre valable en France métropolitaine du 08/10/2024 au 31/12/2024 inclus, exclusivement sur le site internet wwww.nespresso.com, depuis l'application mobile Nespresso, par téléphone au 0 800 55 52 53 (appel et service gratuits, disponibles du lundi au samedi de 8h à 20h, hors 1er mai et jours fériés) ou dans l'une de nos Boutiques Nespresso (selon jours d'ouverture de la Boutique). Bénéficiez d'une remise immédiate de 5€ sur le montant de votre commande de café dès 50 capsules de la gamme Original ou Vertuo achetées. Offre strictement nominative et non transférable, limitée à 1 utilisation par nouveau client (personne physique majeure, non enregistrée sur le site www.nespresso.com ou en boutique Nespresso et ne disposant pas d’un numéro personnel du Club Nespresso) et non cumulable avec toute autre offre Nespresso ou Nestlé en cours ».`}
</span></p>
</div>
  </>);
  }


  export default MentionsLegales;