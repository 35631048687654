import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Inscription from './pages/Inscription'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import './styles/root.scss'
import Footer from 'components/Footer'
import Question1 from 'pages/Question1'
import Question2 from 'pages/Question2'
import Question3 from 'pages/Question3'
import SurveyResultat from 'pages/SurveyResultat'
import SurveyResultatWinner from 'pages/SurveyResultatWinner'
import SurveyResultatLooser from 'pages/SurveyResultatLost'
import GameFinished from 'pages/GameFinished'
import gameDates from 'resources/gameDates'
import moment from 'moment'
import ComingSoon from 'pages/ComingSoon'
import ComingSoonNoDate from 'pages/ComingSoonNoDate'

const RootComponent: React.FC = () => {

    return (
        <Router>
            <Routes> 

                    <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.SELECTOR_QUESTION1_ROUTE} replace={true} />}></Route>
                    <Route path={ROUTES.SELECTOR_HOME} element={<Navigate to={ROUTES.SELECTOR_QUESTION1_ROUTE} replace={true} />}></Route>
                    <Route path={ROUTES.SELECTOR_QUESTION1_ROUTE} element={<Question1 />} />
                    <Route path={ROUTES.SELECTOR_QUESTION2_ROUTE} element={<Question2 />} />
                    <Route path={ROUTES.SELECTOR_QUESTION3_ROUTE} element={<Question3 />} />
                    <Route path={ROUTES.SELECTOR_RESULTAT_SURVEY_ROUTE} element={<SurveyResultat />} />

                    <Route path={ROUTES.JEU_GAME_OVER} element={<GameFinished />}></Route>
                    <Route path={ROUTES.JEU_COMING_SOON}element={<ComingSoon />}></Route>
                    <Route path={ROUTES.JEU_COMING_SOON_NO_DATE}element={<ComingSoonNoDate />}></Route>
                    <Route path={ROUTES.JEU_INSCRIPTION_ROUTE} element={<LayoutCheckDateLimit><Inscription /></LayoutCheckDateLimit>} />
                    <Route path={ROUTES.JEU_QUESTION1_ROUTE} element={<LayoutCheckDateLimit><Question1 /></LayoutCheckDateLimit>} />
                    <Route path={ROUTES.JEU_QUESTION2_ROUTE} element={<LayoutCheckDateLimit><Question2 /></LayoutCheckDateLimit>} />
                    <Route path={ROUTES.JEU_QUESTION3_ROUTE} element={<LayoutCheckDateLimit><Question3 /></LayoutCheckDateLimit>} />
                    <Route path={ROUTES.JEU_RESULTAT_SURVEY_ROUTE} element={<LayoutCheckDateLimit><SurveyResultat /></LayoutCheckDateLimit>} />
                    <Route path={ROUTES.JEU_GAME_WIN_ROUTE} element={<LayoutCheckDateLimit><SurveyResultatWinner /></LayoutCheckDateLimit>} />
                    <Route path={ROUTES.JEU_GAME_LOST_ROUTE} element={<LayoutCheckDateLimit><SurveyResultatLooser type="Perdu"/></LayoutCheckDateLimit>} />
                    <Route path={ROUTES.JEU_GAME_ALREADY_PLAYED_ROUTE} element={<LayoutCheckDateLimit><SurveyResultatLooser type="DejaJoue"/></LayoutCheckDateLimit>} />

                    <Route path={"*"} element={<NotFoundPage />} />
            </Routes>
            <Footer/>
        </Router>
    )
}

function LayoutCheckDateLimit({ children }: { children: JSX.Element }) {

    // TODO a supprimer
    return <Navigate to={ROUTES.JEU_COMING_SOON_NO_DATE} replace />;

    if (moment().isAfter(gameDates.dateToMoment)) {
      return <Navigate to={ROUTES.JEU_GAME_OVER} replace />;
    }

    if (moment().isBefore(gameDates.dateFromMoment)) {
        return <Navigate to={ROUTES.JEU_COMING_SOON} replace />;
    }

    return children;
  }

export default RootComponent