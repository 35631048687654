import moment from "moment";

export default {
    dateFromLong: "8 octobre 2024",
    dateFromShort: "08/10/2024",
    dateFromMoment: moment("2024-10-08 06:00:00"),
    
    dateToLong: "31 octobre 2024",
    dateToShort: "31/10/2024",
    dateToMoment: moment("2024-10-31 23:59:00"),
}